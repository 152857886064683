import React from 'react';
import styled from 'styled-components';

const TimeContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

import { Text } from 'components';
import moment from 'moment';
import { convertToDaysHoursNoSeconds, getPercentHour } from '../../../utils/helpers';

const PoLifecycle = ({ order }) => {
  let startDate = '';
  let startTime = '';
  let elapsedTime = '';
  let percentDowntime = '0';
  let totalDowntime = '';
  if (order?.length) {
    if (order[0]?.ts_start != '') {
      startDate = moment(order[0]?.ts_start).format('MM/DD');
      startTime = moment(order[0]?.ts_start).format('HH:mm');
      let value = moment().diff(order[0]?.ts_start, 'seconds');
      elapsedTime = convertToDaysHoursNoSeconds(value, 'seconds');
      percentDowntime = getPercentHour(value, order[0]?.stopped_time);
      totalDowntime = convertToDaysHoursNoSeconds(order[0]?.stopped_time, 'minutes');
    }
  }
  
  return (

    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
      <TimeContainer>
        <Text isBold> {startDate} <small>&#9679;</small> {startTime}</Text>
        <Text> Hora Início </Text>
      </TimeContainer>
      <TimeContainer>
        <Text isBold>{elapsedTime}</Text>
        <Text> Tempo Transcorrido </Text>
      </TimeContainer>
      <TimeContainer>
        <Text isBold>{totalDowntime} ({percentDowntime}%)</Text>
        <Text> Tempo de parada </Text>
      </TimeContainer>
    </div>


  );

};

export default PoLifecycle;
