/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';

import moment from 'moment';
import { MainContext } from '../../contexts/mainContext';
import { auth } from 'services/firebase';

import { Spacer, Title, Group, Grid, Row, Col } from 'components';
import { Container, HistoryContainer, FlexContainer } from './styles';
import SectionHeader from 'compositions/SectionHeader';
import EditionTable from '../../components/BoxEditionTable';

import { removeInfo } from '../../utils/storage';
import { GetBoxesList } from '../../graphql/queries/queries.graphql';
import {  useLazyQuery } from '@apollo/react-hooks';

export default function BoxesEdition(props) {
  const { idEquip } = props;
  let id = Number(idEquip);
  const { state, dispatch } = useContext(MainContext);
  const { id_enterprise, equipment, boxes_edition } = state;
  const [refreshBoxes, setRefreshBoxes] = useState(true);

  const [getBoxes, { data, error, loading }] = useLazyQuery(GetBoxesList, {
    variables: { id_equipment: id, _gt: moment().subtract(10, 'days').format('YYYY-MM-DD HH:mm:ss') },
    onCompleted: (data) => {
      // alert(JSON.stringify(data?.v_scanned_boxes));
      // dispatch({ type: 'BOX_EDITIONS', payload: data?.v_scanned_boxes });
    }
  });


  useEffect(() => {
    if (refreshBoxes) {
      if (!id_enterprise) {
        auth().signOut();
        removeInfo();
      } else {
        getBoxes({ variables: { id_equipment: id, _gt: moment().subtract(10, 'days').format('YYYY-MM-DD HH:mm:ss') } });
        setRefreshBoxes(false);
      }
    }
  }, [refreshBoxes]);


  const refreshBoxesFunction = () => {
    setRefreshBoxes(true);
  }


  return (
    <Container>
      <Grid fluid>
        <Row between="xs">
          <Col xs={12}>
            <Group isCentered>
              <Title isMedium isBold>
                Linha {state?.equipment?.name}
              </Title>
            </Group>
          </Col>
        </Row>
        <Spacer isLarge isVertical />
      </Grid>

      <Spacer isVertical size={20} />

      <SectionHeader icon="ChartBars" title="Gerenciar Caixas" />

      <Spacer isVertical size={5} />

      <HistoryContainer>
        <EditionTable boxes_edition={data?.v_scanned_boxes} refreshBoxes={refreshBoxesFunction}/>
      </HistoryContainer>

    </Container>
  );
};

