import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
// import Datetime from 'react-datetime';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import 'moment/locale/pt-br';
import { MainContext } from '../../contexts/mainContext';


import './style.css';

import { Modal, Content, Header, Footer, Body } from './styles';
import { FaRegTimesCircle } from 'react-icons/fa';

import { useSubscription, useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { SET_BOX_EDITION, DELETE_BOX } from '../../graphql/mutations';

function BoxDetailsModal({ onCloseModal, box }) {
  const [waitingAdd, setWaitingAdd] = useState(false);
  const [addSucessfull, setAddSucessfull] = useState(null);

  const { state, dispatch } = useContext(MainContext);
  const { id_enterprise, equipment } = state;

  const closeWithDelay = async () => {
    if (addSucessfull) {
      await new Promise(res => setTimeout(res, 1000));
      onCloseModal;
    }
  }

  useEffect(() => {
    closeWithDelay;
  }, [addSucessfull]);

  const [
    deleteBox,
    {
      data: boxDeleteData
    }] = useMutation(DELETE_BOX);



  useEffect(() => {
    const closeOnEscapeKey = (e) => {
      if ((e.charCode || e.keyCode) === 27) {
        onCloseModal();
      }
    };

    document.body.addEventListener('keydown', closeOnEscapeKey);
    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKey);
    };
  }, [onCloseModal]);

  // useEffect(() => {
  //   if (waitingAdd && dataExistenceOfBox && !loadingExistenceOfBox && !errorExistenceOfBox) {
  //     if (dataExistenceOfBox?.scanned_boxes.length > 0) {
  //       alert('Já existe um box com esse número de ordem e número de caixa');
  //       setWaitingAdd(false);
  //     } else {

  //       // setBoxEdition({
  //       //   variables: {
  //       //     box: box.box_order_number,
  //       //     qtd: box.increment,
  //       //     id_enterprise,
  //       //     id_equip: equipment?.id,
  //       //     id_order: box.id_order,
  //       //     id_prod_order: box.id_production_order,
  //       //     site: id_enterprise,
  //       //     ts: box.ts_value.format(),
  //       //     id_area: box.id_area,
  //       //     id_box: box.id_box,
  //       //     deleted: true
  //       //   }
  //       // }
  //       // );
  //     }
  //   }
  // }, [dataExistenceOfBox, loadingExistenceOfBox, errorExistenceOfBox, waitingAdd]);

  // useEffect(() => {
  //   if (waitingAdd && boxData) {
  //     setWaitingAdd(false);
  //     setAddSucessfull(true);
  //   }
  // }, [boxData]);

  const LoadingIndicator = props => {
    return (
      waitingAdd &&
      <div className="spinner-grow text-light" role="status"><span className="visually-hidden">Loading...</span></div>
    )
  }

  const handleChangeSubmit = async () => {
    setWaitingAdd(true);
    setAddSucessfull(null);
    // alert(JSON.stringify(box));
    try {
      // setBoxEdition(
      //   {
      //     variables: {
      //       box: box.box_order_number,
      //       qtd: box.increment,
      //       id_enterprise,
      //       id_equip: equipment?.id,
      //       id_order: box.id_order,
      //       id_prod_order: box.id_production_order,
      //       site: id_enterprise,
      //       ts: moment(box.ts_value).format(),
      //       id_box: box.id_box,
      //       deleted: true
      //     }
      //   }
      // );


      // alert(box.id_box);
      deleteBox({
        variables: {
          id_box: box.id_box
        }
      });

    } catch (err) {
      console.log(err);
      alert(err);
    }
  };

  if (!box) {
    return null;
  }


  return (
    <Modal className="modal hide" onClick={onCloseModal} id="UploadDowntimeModal" tabIndex="-1">
      <div className="modal-dialog hide">
        <Content onClick={(e) => { e.stopPropagation() }}>
          <Header>
            <h5 className="modal-title">Histórico:</h5>
            <FaRegTimesCircle onClick={onCloseModal} />
          </Header>
          <Body>
            <div className="form-group">
              <p>Você está vendo o histórico da seguinte caixa:</p>
              <p>OP: <strong>{box?.id_order}</strong></p>
              <p>Número da caixa: <strong>{box?.box_order_number}</strong></p>
              <br />
              <table>
                <tbody>
                  {box?.scanned_boxes_editions?.map((item, index) =>
                  (
                    <>
                      <tr key={index}>
                        <td style={{ verticalAlign: 'middle' }}>Horário da modificação: <strong>{moment(item.ts_edited).format('HH:mm:ss DD/MM/YY')}</strong></td>
                        <td style={{ verticalAlign: 'middle' }}>Horário da caixa: <strong>{moment(item.ts_value).format('HH:mm:ss DD/MM/YY')}</strong></td>
                        <td style={{ verticalAlign: 'middle' }}>Quantidade: <strong>{item.increment}</strong></td>
                      </tr>
                    </>
                  )
                  )
                  }
                </tbody>

              </table>



            </div>
          </Body>

        </Content>
      </div>
    </Modal>
  );
}

export default BoxDetailsModal;