/* eslint-disable no-unused-vars */
import React from 'react';
import { Router } from '@reach/router';

import Home from 'containers/Home';
import Lines from 'containers/Lines';
import SiteManager from 'containers/SiteManager';
import BoxesEdition from 'containers/BoxesEdition';

const Routes = (props) => (
  <Router style={{ flex: 1, display: 'flex', overflow: 'auto' }}>
    <SiteManager path="/" />
    <Home path="/current/:idEquip" />
    <BoxesEdition path="/edition/:idEquip" />
    <SiteManager path="/site" />
    <Lines path="/lines" />
  </Router>
);

export default Routes;
