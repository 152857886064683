import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 10px;
  padding: 5px;
`;

export const AddBoxButton = styled.button`
  width: 50%;
  height: 40px;
  border: none;
  color: #fff;
  background: #2F80ED;
  border-radius: 8px;
  outline: none;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  margin-bottom: 15px;
`;
