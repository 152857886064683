import React from 'react';
import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';

import { Group, Fill, Icon, Button } from 'components';
import { MenuBars } from 'assets/icons';

import colors from 'components/colors';

import PackIOTLogo from 'statics/media/packiot-logo.svg';

const Wrapper = styled(Group)`
  background-color: ${colors.dark};
  height: 72px;
  padding-left: 40px;
  padding-right: 40px;
  flex-shrink: 0;
  position: relative;
  z-index: 100;
`;

const Edges = styled.div`
  width: 100px;
  ${({ isRight }) => isRight && css`text-align: right;`}
`;

const Center = styled(Fill)`
  width: 100px;
  text-align: center;
`;

const AppHeader = ({ onTouchMenu }) => (
  <Wrapper isFullWidth isVerticalCentered>
    <Edges>
      <Button isBare onClick={onTouchMenu}>
        <Icon color={colors.white}>
          <MenuBars />
        </Icon>
      </Button>
    </Edges>
    <Center><PackIOTLogo fill="#FFF" /></Center>
    <Edges isRight />
  </Wrapper>
);

AppHeader.propTypes = {};

export default AppHeader;
