/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { MainContext } from '../../contexts/mainContext';
import { auth } from 'services/firebase';

import { navigate } from '@reach/router';

import { Spacer, Error, Loading, Text, NoConnection, Modal } from 'components';
import { Container, BoxContainer, HistoryContainer, FlexContainer, EditButton } from './styles';
import PoHeader from 'compositions/PoHeader';
import PoLifecycle from 'compositions/PoLifecycle';
import PoQuantity from 'compositions/PoQuantity';
import SectionHeader from 'compositions/SectionHeader';
import History from '../../components/historyBox/History';
import MissingBox from '../../components/historyBox/MissingBox';

import { removeInfo } from '../../utils/storage';
import { getMissingBoxes } from '../../utils/helpers';
import { GetOrderInfo } from '../../graphql/subscriptions/subscriptions.graphql';
import { GetOrderInfo2, GetTotalBoxes, CurrentShift } from '../../graphql/queries/queries.graphql';
import { useSubscription, useLazyQuery } from '@apollo/react-hooks';
import { FaPencilAlt } from 'react-icons/fa';

export default function Home(props) {
  const { idEquip } = props;
  let id = Number(idEquip);
  const [updateModal, setUpdateModal] = useState(false);
  const { state, dispatch } = useContext(MainContext);
  const { currentOrder: order, isOffline, boxes, userName, id_enterprise, equipment } = state;
  //console.log(state);
  let lastBox = null;
  let totalBoxes = null;
  let totalBoxesQuantity = 0;
  let historyBoxes = null;
  let missing = null;
  
  const [
    GetInitialOrderInfo,
    { 
      data: currentOrderData,
      loading: currentOrderLoading,
    }
  ] = useLazyQuery(GetOrderInfo2, { 
    variables: { id_equipment: id },
    onCompleted: (data) => {
      if (data?.v_current_production_orders?.length) {
        dispatch({ type: 'CURRENT_ORDER', payload: data.v_current_production_orders });
        getTotalBoxes({ 
          variables: {
            id_equipment: id,
            id_production_order: data.v_current_production_orders[0].id_production_order
          } 
        });
      } 
    } 
  });

  const [getTotalBoxes, { data: totalBoxData, error: totalBoxError }] = useLazyQuery(GetTotalBoxes, {
    onCompleted: (data) => {
      dispatch({ type: 'BOX_HISTORY', payload: data?.v_scanned_boxes });
    } 
  });
  const [getShift, { data: shiftData }] = useLazyQuery(CurrentShift, {
    variables: { id_equipment: id },
    onCompleted: () => {
      if (isOffline) dispatch({ type: 'NETWORK_STATUS', payload: false });
    },
    onError: (error) => {
      if (error.networkError) dispatch({ type: 'NETWORK_STATUS', payload: true });
    }
  });

  useSubscription(GetOrderInfo, { 
    variables: { id_equipment: id },
    onSubscriptionData: (data) => {
      if (data?.subscriptionData?.data?.v_current_production_orders?.length) {
        dispatch({ type: 'CURRENT_ORDER', payload: data.subscriptionData.data.v_current_production_orders });
      }
    } 
  });

  useEffect(() => {
    if (!id_enterprise) { 
      auth().signOut();
      removeInfo();
    } else {
      GetInitialOrderInfo();
      getShift();
    }
    let checkConnection = setInterval(() => {
      fetch('https://gql.packiot.com/healthz')
      .then((response) => { 
        if (response?.status !== 200) {
          dispatch({ type: 'NETWORK_STATUS', payload: true });
        } else {
          if (isOffline) dispatch({ type: 'NETWORK_STATUS', payload: false });
        }
      }
      )
      .catch((error) => {
        if (error.message === 'Failed to fetch') {
          dispatch({ type: 'NETWORK_STATUS', payload: true });
        } 
      });
    }, 45000);
    if (localStorage.getItem('@barcode:update') === 'true') setUpdateModal(true);
    
    return () => {
      clearInterval(checkConnection);
    };
    
    
  }, []);


  if (currentOrderData) {
    if (!currentOrderData.v_current_production_orders.length) {
      return <Error error="Nenhuma ordem de produção encontrada x_x" />;
    } 
  } 
  if (totalBoxData) {
    let data = null;
    if (totalBoxData) {
      data = totalBoxData.v_scanned_boxes;
    } 
    if (data?.length) {
      lastBox = data[0]?.box_order_number;
      historyBoxes = data;
      let newBoxes = [...data];
      let storageBoxes = null; //getStorageBoxes();
      if (storageBoxes) {
        storageBoxes = JSON.parse(storageBoxes);
        for (let i in storageBoxes) {
          let boxArrayData = storageBoxes[i].barcode.split(';');
          newBoxes.push({ box_order_number: Number(boxArrayData[1]) });
        }
      }
      let numbers = newBoxes.map((item) => item.box_order_number);
      missing = getMissingBoxes(numbers);
    } else {
      lastBox = null;
    }
    totalBoxesQuantity = totalBoxData?.scanned_boxes_aggregate?.aggregate?.sum?.increment;
    if (!totalBoxesQuantity) totalBoxesQuantity = 0;
    totalBoxes = data?.length;
  }
  
  if (currentOrderLoading) return <Loading />;

  return (
    <Container>
      { isOffline && <NoConnection /> }
      <PoHeader operator={userName} order={order} />

      <Spacer isVertical size={20} />

      <BoxContainer>
        <Text>Número da ultima caixa: <b> {lastBox} </b></Text>
        <Text>Quantidade de caixas: <b>{totalBoxes}</b></Text>
      </BoxContainer>
      

      <SectionHeader icon="ChartBars" title="Quantidade" />

      <PoQuantity boxQuantity={totalBoxesQuantity} order={order} />

      <Spacer isVertical size={20} />

      <SectionHeader icon="Clock" title="Tempo" />

      <PoLifecycle order={order} />

      <Spacer isVertical size={40} />

      <EditButton onClick={()=>{navigate(`${process.env.baseURL}edition/${id}`)}}>
        <FaPencilAlt style={{marginRight: "5px"}} />{' Editar caixas'}
      </EditButton>

      {/* Refresh to update   */ }
      <Modal isOpen={updateModal} onClickToClose={() => setUpdateModal(false)}>
        <FlexContainer size={60} direction="column">
          <p style={{ fontWeight: 'bold', fontSize: 22 }}>Nova atualização disponível!&nbsp;&nbsp;</p>
        </FlexContainer>
        
        <FlexContainer size={60} direction="row">
          <button className="cancel-btn" onClick={() => setUpdateModal(false)}>Fechar</button>
          <button className="barcode-btn" onClick={() => {
            localStorage.removeItem('@barcode:update');
            window.location.reload();
          }}>
            Atualizar
          </button>
        </FlexContainer>
        
      </Modal>

      <HistoryContainer>
        <History boxes={historyBoxes} />
        {(missing && missing.length) ? <MissingBox missing={missing} /> : ''}
      </HistoryContainer>
      
    </Container>
  );
};

