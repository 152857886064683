import styled from 'styled-components';

export const Container = styled.div`
  width: ${(props) => props.size}%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 10px;
  padding: 5px;
`;
