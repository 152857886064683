/* eslint-disable max-len */
import gql from 'graphql-tag';

export const SET_BOX = gql`
    mutation SetBox($box: Int, $id_enterprise: bigint, $id_equip: bigint, 
    $id_order: bigint, $id_prod_order: bigint, $site: bigint, $ts: timestamptz, $qtd: Int) {
    insert_scanned_boxes(objects: {
      box_order_number: $box, 
      increment: $qtd,
      id_enterprise: $id_enterprise, 
      id_equipment: $id_equip, 
      id_order: $id_order, 
      id_production_order: $id_prod_order, 
      id_site: $site, 
      ts_value: $ts
      }) {
    affected_rows
  }
}
`;

export const SET_MULTIPLE_BOXES = gql`
  mutation insert_multiple_boxes($objects: [scanned_boxes_insert_input!]! ) {
  insert_scanned_boxes(objects: $objects) {
    returning {
      box_order_number
    } 
  }
}
`;

// export const SET_BOX_EDITION = gql`
//     mutation SetBoxEdition($box: Int, $id_enterprise: bigint, $id_equip: bigint, 
//     $id_order: bigint, $id_prod_order: bigint, $site: bigint, $ts: timestamptz, $qtd: Int,
//     $deleted: Boolean, $area: bigint, $id_box: bigint) {
//     insert_scanned_boxes_editions(objects: {
//       box_order_number: $box, 
//       increment: $qtd,
//       id_enterprise: $id_enterprise, 
//       id_equipment: $id_equip, 
//       id_order: $id_order, 
//       id_production_order: $id_prod_order, 
//       id_site: $site, 
//       id_box: $id_box,
//       ts_value: $ts,
//       deleted: $deleted
//       }) {
//     affected_rows
//   }
// }
// `;

export const SET_BOX_EDITION = gql`
mutation MyMutation($ts_value: timestamptz, $increment: Int, $id_site: bigint, $id_production_order: bigint, $id_order: bigint, $id_equipment: bigint, $id_enterprise: bigint, $id_box: bigint, $deleted: Boolean = false, $box_order_number: Int) {
  insert_scanned_boxes_editions(objects: {box_order_number: $box_order_number, deleted: $deleted, id_box: $id_box, id_enterprise: $id_enterprise, id_equipment: $id_equipment, id_order: $id_order, id_production_order: $id_production_order, id_site: $id_site, increment: $increment, ts_value: $ts_value}) {
    affected_rows
  }
}
`;

export const DELETE_BOX = gql`
mutation DeleteBox($id_box: bigint
  $ts_value: timestamptz, $increment: Int, $id_site: bigint, $id_production_order: bigint, $id_order: bigint, $id_equipment: bigint, $id_enterprise: bigint, $deleted: Boolean = false, $box_order_number: Int) {
    insert_scanned_boxes_editions(objects: {box_order_number: $box_order_number, deleted: $deleted, id_box: $id_box, id_enterprise: $id_enterprise, id_equipment: $id_equipment, id_order: $id_order, id_production_order: $id_production_order, id_site: $id_site, increment: $increment, ts_value: $ts_value}) {
    affected_rows
  }
  delete_scanned_boxes(where: {id_box: {_eq: $id_box}}){
    affected_rows
  }
} 
`;

export const UPDATE_BOX = gql`
mutation MyMutation($id_box: bigint, $new_ts_value: timestamptz, $new_increment: Int
  $ts_value: timestamptz, $increment: Int, $id_site: bigint, $id_production_order: bigint, $id_order: bigint, $id_equipment: bigint, $id_enterprise: bigint, $deleted: Boolean = false, $box_order_number: Int) {
  insert_scanned_boxes_editions(objects: {box_order_number: $box_order_number, deleted: $deleted, id_box: $id_box, id_enterprise: $id_enterprise, id_equipment: $id_equipment, id_order: $id_order, id_production_order: $id_production_order, id_site: $id_site, increment: $increment, ts_value: $ts_value}) {
    affected_rows
  }
  update_scanned_boxes(where: {id_box: {_eq: $id_box}}, _set: {ts_value: $new_ts_value, increment: $new_increment}) {
    affected_rows
  }
}
`;
