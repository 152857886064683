import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
// import Datetime from 'react-datetime';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import 'moment/locale/pt-br';
import { MainContext } from '../../contexts/mainContext';


import './style.css';

import { Modal, Content, Header, Footer, Body } from './styles';
import { FaRegTimesCircle } from 'react-icons/fa';

import { useSubscription, useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { SET_BOX_EDITION, DELETE_BOX } from '../../graphql/mutations';

function BoxDeleteModal({ onCloseModal, box, refreshBoxes }) {
  const [waitingAdd, setWaitingAdd] = useState(false);
  const [addSucessfull, setAddSucessfull] = useState(null);

  const { state, dispatch } = useContext(MainContext);
  const { id_enterprise, equipment, id_site } = state;

  const closeWithDelay = async () => {
    if (addSucessfull) {
      await new Promise((res) => setTimeout(res, 1000));
      onCloseModal();
      refreshBoxes();
    }
  };

  useEffect(() => {
    closeWithDelay();
  }, [addSucessfull]);



  const [
    deleteBox,
    {
      data: boxDeleteData
    }] = useMutation(DELETE_BOX);

  useEffect(() => {
    if (waitingAdd && boxDeleteData) {
      setWaitingAdd(false);
      setAddSucessfull(true);
    }
  }, [boxDeleteData]);


  useEffect(() => {
    const closeOnEscapeKey = (e) => {
      if ((e.charCode || e.keyCode) === 27) {
        onCloseModal();
      }
    };

    document.body.addEventListener('keydown', closeOnEscapeKey);
    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKey);
    };
  }, [onCloseModal]);

  // useEffect(() => {
  //   if (waitingAdd && dataExistenceOfBox && !loadingExistenceOfBox && !errorExistenceOfBox) {
  //     if (dataExistenceOfBox?.scanned_boxes.length > 0) {
  //       alert('Já existe um box com esse número de ordem e número de caixa');
  //       setWaitingAdd(false);
  //     } else {

  //       // setBoxEdition({
  //       //   variables: {
  //       //     box: box.box_order_number,
  //       //     qtd: box.increment,
  //       //     id_enterprise,
  //       //     id_equip: equipment?.id,
  //       //     id_order: box.id_order,
  //       //     id_prod_order: box.id_production_order,
  //       //     site: id_enterprise,
  //       //     ts: box.ts_value.format(),
  //       //     id_area: box.id_area,
  //       //     id_box: box.id_box,
  //       //     deleted: true
  //       //   }
  //       // }
  //       // );
  //     }
  //   }
  // }, [dataExistenceOfBox, loadingExistenceOfBox, errorExistenceOfBox, waitingAdd]);

  // useEffect(() => {
  //   if (waitingAdd && boxData) {
  //     setWaitingAdd(false);
  //     setAddSucessfull(true);
  //   }
  // }, [boxData]);

  const LoadingIndicator = (props) => (
    waitingAdd &&
    <div className="spinner-grow text-light" role="status"><span className="visually-hidden">Loading...</span></div>
  );

  const handleChangeSubmit = async () => {
    setWaitingAdd(true);
    setAddSucessfull(null);
    // alert(JSON.stringify(box));
    try {
      // setBoxEdition(
      //   {
      //     variables: {
      //       box: box.box_order_number,
      //       qtd: box.increment,
      //       id_enterprise,
      //       id_equip: equipment?.id,
      //       id_order: box.id_order,
      //       id_prod_order: box.id_production_order,
      //       site: id_enterprise,
      //       ts: moment(box.ts_value).format(),
      //       id_box: box.id_box,
      //       deleted: true
      //     }
      //   }
      // );


      // alert(box.id_box);
      deleteBox({
        variables: {
          box_order_number: box.box_order_number,
          increment: box.increment,
          id_enterprise,
          id_equipment: parseInt(equipment?.id),
          id_order: box.id_order,
          id_production_order: box.id_production_order,
          id_site: id_site,
          ts_value: moment(box.ts_value).format(),
          id_box: box.id_box,
          deleted: true
        }
      });

    } catch (err) {
      console.log(err);
      alert(err);
    }
  };

  if (!box) {
    return null;
  }


  return (
    <Modal className="modal hide" onClick={onCloseModal} id="UploadDowntimeModal" tabIndex="-1">
      <div className="modal-dialog hide">
        <Content onClick={(e) => {
          e.stopPropagation();
        }}>
          <Header>
            <h5 className="modal-title">Apagar caixa:</h5>
            <FaRegTimesCircle onClick={onCloseModal} />
          </Header>
          <Body>
            <div className="form-group">
              <p>Você está prestes a <strong>excluir permanentemente</strong> a seguinte caixa:</p>
              <p>OP: {box?.id_order}</p>
              <p>Número da caixa: {box?.box_order_number}</p>
            </div>
          </Body>

          <Footer>
            {addSucessfull != null ?
              (
                addSucessfull ? <p className=".text-success" >Caixa exluída com sucesso!</p> : <p className=".text-danger">Erro ao excluir caixa!</p>
              ) :
              <></>
            }
            <button type="button" className={`btn btn-secondary ${waitingAdd ? 'disabled' : ''}`} onClick={onCloseModal} >Fechar</button>
            <button onClick={() => handleChangeSubmit()} type="button" >{waitingAdd ? <LoadingIndicator /> : <></>}{waitingAdd ? '' : 'Excluir'}</button>
          </Footer>
        </Content>
      </div>
    </Modal>
  );
}

export default BoxDeleteModal;