import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
// import Datetime from 'react-datetime';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import 'moment/locale/pt-br';
import { MainContext } from '../../contexts/mainContext';


import './style.css';

import { Modal, Content, Header, Footer, Body } from './styles';
import { FaRegTimesCircle } from 'react-icons/fa';

import { CheckExistenceOfBox, GetPoList } from '../../graphql/queries/queries.graphql';
import { useSubscription, useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { SET_BOX } from '../../graphql/mutations';

function BoxAddModal({ onCloseModal, refreshBoxes }) {
  const [waitingAdd, setWaitingAdd] = useState(false);
  const [addSucessfull, setAddSucessfull] = useState(null);
  const [newBox, setNewBox] = useState({ ts_value: moment() });

  const { state, dispatch } = useContext(MainContext);
  const { id_enterprise, equipment, id_site } = state;

  const { data, error, loading } = useQuery(GetPoList, { variables: { id_equipment: equipment?.id, _gt: moment().subtract(20, 'days').format('YYYY-MM-DD HH:mm:ss') } });

  const [checkExistenceOfBox, { data: dataExistenceOfBox, error: errorExistenceOfBox, loading: loadingExistenceOfBox }] = useLazyQuery(CheckExistenceOfBox);

  const closeWithDelay = async () => {
    if (addSucessfull) {
      await new Promise((res) => setTimeout(res, 1000));
      onCloseModal();
      refreshBoxes();
    }
  };

  useEffect(() => {
    closeWithDelay();
  }, [addSucessfull]);

  const [
    setScannedBox,
    {
      data: boxData
    }] = useMutation(SET_BOX);

  useEffect(() => {
    const closeOnEscapeKey = (e) => {
      if ((e.charCode || e.keyCode) === 27) {
        onCloseModal();
      }
    };

    document.body.addEventListener('keydown', closeOnEscapeKey);
    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKey);
    };
  }, [onCloseModal]);

  useEffect(() => {
    if (waitingAdd && dataExistenceOfBox && !loadingExistenceOfBox && !errorExistenceOfBox) {
      if (dataExistenceOfBox?.scanned_boxes.length > 0) {
        alert('Já existe um box com esse número de ordem e número de caixa');
        setWaitingAdd(false);
      } else {
        let op_data = data.production_orders.find((x) => x.id_production_order == newBox.id_production_order);

        setScannedBox({
          variables: {
            box: newBox.box_order_number,
            qtd: newBox.increment,
            id_enterprise,
            id_equip: equipment?.id,
            id_order: op_data?.id_order,
            id_prod_order: newBox.id_production_order,
            site: id_site,
            ts: moment(newBox.ts_value).format()
          }
        }
        );
      }
    }
  }, [dataExistenceOfBox, loadingExistenceOfBox, errorExistenceOfBox, waitingAdd]);

  useEffect(() => {
    if (waitingAdd && boxData) {
      setWaitingAdd(false);
      setAddSucessfull(true);
    }
  }, [boxData]);

  const LoadingIndicator = (props) => (
    waitingAdd &&
    <div className="spinner-grow text-light" role="status"><span className="visually-hidden">Loading...</span></div>
  );

  const handleChangeSubmit = async () => {
    setWaitingAdd(true);
    setAddSucessfull(null);
    try {
      await checkExistenceOfBox({ variables: { id_equipment: equipment?.id, id_production_order: newBox.id_production_order, box_order_number: parseInt(newBox.box_order_number) } });
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };

  return (
    <Modal className="modal hide" onClick={onCloseModal} id="UploadDowntimeModal" tabIndex="-1">
      <div className="modal-dialog hide">
        <Content onClick={(e) => {
          e.stopPropagation();
        }}>
          <Header>
            <h5 className="modal-title">Adicionar caixa:</h5>
            <FaRegTimesCircle onClick={onCloseModal} />
          </Header>
          <Body>
            <form id="UploadCSVForm" >

              <div>

                <label htmlFor="datetime_input">Horário: </label>

                <Datetime
                  initialValue={moment(newBox?.ts_value).toDate()}
                  timeFormat="HH:mm:ss"
                  dayFormat="DD/MM/YYYY"
                  id="datetime_input"
                  onChange={(e) => {
                    let temp = { ...newBox };
                    temp.ts_value = e;
                    setNewBox(temp);
                  }}
                  locale="pt-br"
                  className="date_picker_modal"
                />


                <label htmlFor="po_input" >Ordem de produção: </label>
                <select
                  id="po_input"
                  value={newBox?.id_production_order}
                  onChange={(e) => {
                    // let temp = { ...newBox };
                    // temp.id_production_order = parseInt(e.target.value);
                    // let op_data = data.production_orders.find(x => x.id_production_order == parseInt(e.target.value));
                    // alert(e.target.value);
                    // alert(JSON.stringify(op_data));

                    // temp.increment(op_data.box_quantity);
                    // alert(JSON.stringify(temp));
                    // setNewBox(JSON.parse(JSON.stringify(temp)));

                    let op_data = data.production_orders.find((x) => x.id_production_order == parseInt(e.target.value));
                    let temp = { ...newBox, id_production_order: parseInt(e.target.value), increment: op_data.box_quantity };
                    setNewBox(JSON.parse(JSON.stringify(temp)));
                  }}
                  style={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}>
                  <option value={null}>-</option>
                  {data?.production_orders?.map((item) => <option value={item?.id_production_order}>{item.id_order}</option>)}
                </select>
              </div>
              <div>

                <label htmlFor="box_order_number" >Número da caixa: </label>
                <input
                  id="box_order_number"
                  value={newBox?.box_order_number}
                  type="number"
                  min={0}
                  max={10000000}
                  onChange={(e) => {
                    let temp = { ...newBox };
                    temp.box_order_number = e.target.value;
                    setNewBox(temp);
                  }}
                  style={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}
                />

                <label htmlFor="incrment_input" >Quantidade: </label>
                <input
                  id="incrment_input"
                  value={newBox?.increment}
                  type="number"
                  min={0}
                  max={10000}
                  onChange={(e) => {
                    let temp = { ...newBox };
                    temp.increment = e.target.value;
                    setNewBox(temp);
                  }}
                  style={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}
                />
              </div>
              <div />
            </form>
          </Body>

          <Footer>
            {addSucessfull != null ?
              (
                addSucessfull ? <p className=".text-success" >Cadastrado com sucesso!</p> : <p className=".text-danger">Erro ao cadastrar!</p>
              ) :
              <></>
            }

            <button type="button" className={`btn btn-secondary ${waitingAdd ? 'disabled' : ''}`} onClick={onCloseModal} >Fechar</button>
            <button onClick={() => handleChangeSubmit()} type="button" disabled={(!newBox?.id_production_order) || (!newBox?.box_order_number) || (!newBox?.ts_value) || (!newBox?.increment)} >{waitingAdd ? <LoadingIndicator /> : <></>}{waitingAdd ? '' : 'Adicionar'}</button>
          </Footer>
        </Content>
      </div>
    </Modal>
  );
}

export default BoxAddModal;