import styled from 'styled-components';

export const Container = styled.div`
  width: ${(props) => props.size}%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 10px;
  padding: 5px;
`;

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  width: 700px;
  background: #fff;
`;

export const Header = styled.div`
  padding: 10px;
  display: flex;
  justify-content: space-between;
`;

export const Footer = styled.div`
  padding: 10px;
`;

export const Body = styled.div`
  padding: 10px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
`;
