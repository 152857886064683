import React from 'react';
import { Container } from './styles';

function MissingBox({ missing }) {
  let showMissingBoxes = [];
  if (missing?.length > 21) showMissingBoxes = missing.slice(0, 20);
  return (
    <Container size={30}>
      <p><strong>Caixas não encontradas:</strong></p>
      <ul>
        {
          showMissingBoxes && showMissingBoxes.map((item) => (
            <li key={item}> Número: <strong>{item}</strong> </li>
          ))
        }
      </ul>
    </Container>
  );
}

export default MissingBox;