import moment from 'moment';
/**
 * @function
 * @description Return capitalized string
 * @param {String} s
 * 
 */
export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

/**
 * @function
 * @description Return if a number is invalid
 * @param {*} number
 */
export function isInvalidNumber(number) {
  return (
    isNaN(number) ||
    !isFinite(number) ||
    number === Infinity ||
    number === 'Infinity' ||
    number === -Infinity ||
    number === '-Infinity' ||
    number === NaN ||
    number === 'NaN' ||
    number === '∞' ||
    number === '-∞' ||
    number === undefined ||
    number === 'undefined' ||
    number === null ||
    number === 'null'
  );
};

export const fixNumber = (number, float, locale = 'pt-br') => {
  if (isInvalidNumber(number)) return number;
  if (float >= 0) {
    return number.toLocaleString(locale, {
      maximumFractionDigits: float,
      minimumFractionDigits: 0
    });
  }
  return number;
};

export function convertToDaysHoursNoSeconds(value, time) {
  //if (isInvalidNumber(value)) return '';
  let durationInSeconds = moment.duration(value, time);
  if (durationInSeconds.days() > 0) {
    return durationInSeconds.days() + 'd ' + moment.utc(durationInSeconds.valueOf()).format('HH:mm');
  }
  return moment.utc(durationInSeconds.valueOf()).format('HH:mm');
};

export function getTotalElapsedTime(value, time) {
  let durationInSeconds = moment.duration(value, time);
  let total = '';
  if (durationInSeconds.days() > 0) total = durationInSeconds.days() + 'd ';
  if (durationInSeconds.hours() > 0) total += durationInSeconds.hours() + 'h ';
  if (durationInSeconds.minutes() > 0) total += durationInSeconds.minutes() + 'm ';
  return total;

}

/**
 * @function
 * @description Return a percent of a determined time
 * @param {number} elapsedTime
 * @param {number} downtime
 */
export function getPercentHour(elapsedTime, downtime) {
  if (elapsedTime <= 60 || downtime === 0) return '0';
  let elapsedMinutes = Math.round(elapsedTime / 60);

  let percent = downtime * 100 / elapsedMinutes;
  return percent.toFixed(1);
};

/**
 * @function
 * @description Return an array of missing numbers
 * @param {Array<Number>} boxes 
 * 
 */

export function getMissingBoxes(boxes) {
  let arrA = boxes;
  let arrB = [];
  if (arrA) {
    arrA.sort((a, b) => b - a);
    for (let i = 0; i < arrA[0]; i++) {
      arrB.push(i + 1);
    }
  }
  let difference = arrB.filter((x) => !arrA.includes(x));
  return difference;
}
