import React from 'react';
import moment from 'moment';

import { Container } from './styles';

function History({ boxes }) {
  let storageBoxes = null;
  let allBoxData = [];
  const initialDate = moment().subtract(24, 'hours').format();
  let boxesFiltered = [];
  if (storageBoxes) {
    storageBoxes = JSON.parse(storageBoxes);
    if (storageBoxes.length) {
      for (let i in storageBoxes) {
        let boxArrayData = storageBoxes[i].barcode.split(';');
        allBoxData.push(
          {
            box_order_number: boxArrayData[1],
            ts_value: storageBoxes[i].timestamp,
            id_order: boxArrayData[0],
            cd_shift: storageBoxes[i].shift
          }
        );
      }
    }
    allBoxData.sort((a, b) => b.box_order_number - a.box_order_number);
    if (allBoxData?.length > 21) allBoxData = allBoxData.slice(0, 20);
  }
  if (boxes && boxes.length) {
    boxesFiltered = boxes.filter((item) => moment(item.ts_value).isSameOrAfter(initialDate));
    if (boxesFiltered?.length > 21) boxesFiltered = boxesFiltered.slice(0, 20);
  }

  return (
    <Container size={70}>
      <p><strong>Caixas lidas:</strong></p>
      <table>
        
        <tbody>  
          {
            allBoxData && allBoxData.map((item) => (
              <tr key={item.box_order_number}>
                <td>Número: <strong>{item.box_order_number}</strong></td>
                <td>Op: <strong>{item.id_order}</strong></td>
                <td>Turno: <strong>{item.cd_shift}</strong></td>
                <td>Horário: <strong>{moment(item.ts_value).format('HH:mm:ss DD/MM/YYYY')}</strong></td>
                <td>Status: <strong>Pendente</strong></td>              
              </tr>
            ))
          }
          {
            boxesFiltered && boxesFiltered.map((item) => (
              <tr key={item.box_order_number}>
                <td>Número: <strong>{item.box_order_number}</strong></td>
                <td>Op: <strong>{item.id_order}</strong></td>
                <td>Turno: <strong>{item.cd_shift}</strong></td>
                <td>Horário: <strong>{ moment(item.ts_value).format('HH:mm:ss DD/MM/YYYY') }</strong></td>
                <td>Status: <strong>Ok</strong></td>
              </tr>
            ))
          }
        </tbody>
    
      </table>
    </Container>
  );
}

export default History;