import React from 'react';
import styled from 'styled-components';
import { MdCloudOff } from 'react-icons/md';

const Container = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -30px;
`;

const Card = styled.div`
  width: 250px;
  height: 50px;
  background: #fff;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
`;

const Text = styled.p`
  font-weight: bold;
  font-size: 16px;
`;

function NoConnection() {
  return (
    <Container>
      <Card>
        <Text>Sem conexão com a Internet &nbsp;&nbsp; </Text> <MdCloudOff size={22} />
      </Card>
    </Container>
  );
}

export default NoConnection;