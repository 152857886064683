const prefix = '@barcode-scanner:';
const FIREBASE_TOKEN = `${prefix}token`;
const STATE = `${prefix}state`;

export const getToken = () => localStorage.getItem(FIREBASE_TOKEN);
export const getState = () => {
  try {
    const data = JSON.parse(localStorage.getItem(STATE));
    return data;
  } catch (error) {
    return null;
  }
};

export const setToken = (token) => {
  localStorage.setItem(FIREBASE_TOKEN, token);
};

export const setState = (data) => {
  data = JSON.stringify(data);
  localStorage.setItem(STATE, data);
};

export const removeInfo = () => {
  localStorage.clear();
};
