/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext } from 'react';

import { removeInfo } from '../../utils/storage';

import { MdChevronRight } from 'react-icons/md';

import Routes from './Routes';

import colors from 'components/colors';
import { navigate } from '@reach/router';

import AppHeader from 'compositions/AppHeader';

import useDrawer from 'hooks/useDrawer';
import { auth } from 'services/firebase';

import { SessionContext } from 'containers/SessionManager';
import { Container } from './styles';

export const MainContext = React.createContext({});

export default function MainContainer() {
  
  const { session, isReady } = useContext(SessionContext);

  const [drawer, toggleDrawer] = useDrawer({ paddingTop: 72, backgroundColor: colors.light });

  if (!isReady) return null;

  return (
    <MainContext.Provider value={{}}>
      <div style={{ backgroundColor: colors.light, display: 'flex', height: '100vh', flexDirection: 'column' }}>

        <AppHeader onTouchMenu={toggleDrawer} />
        <Routes />

        {drawer(
          <Container>
            <ul>

              <li onClick={() => {
                navigate(`${process.env.baseURL}`);
                toggleDrawer();
              }}>
                Linhas
                <MdChevronRight size={18} color="#4a4a4a" />
              </li>

              <li onClick={() => {
                auth().signOut(); removeInfo();
              }}> Sair
                <MdChevronRight size={18} color="#4a4a4a" />
              </li>
            </ul>

          </Container>
        )}

      </div>
    </MainContext.Provider>
  );
};

