import { getState, setState } from '../utils/storage';
let contextPersist = getState();
export const INITIAL_STATE = contextPersist || {
  currentOrder: [],
  equipment: {},
  isOffline: false,
  boxes: [],
  boxes_edition: [],
  buffer: [],
  userName: '',
  id_enterprise: null,
  id_site: null
};

export const mainReducer = (state = INITIAL_STATE, action = {}) => {

  switch (action.type) {

    case 'CURRENT_ORDER':
      state = {
        ...state,
        ...{ currentOrder: action.payload }
      };
      setState(state);
      break;

    case 'USER_INFO':
      state = {
        ...state,
        ...{ userName: action.payload.name, id_enterprise: action.payload.id, id_site: action.payload.id_site }
      };
      setState(state);
      break;

    case 'BOX_HISTORY':
      state = {
        ...state,
        ...{ boxes: action.payload }
      };
      setState(state);
      break;

    case 'BOX_EDITIONS':
      state = {
        ...state,
        ...{ boxes_edition: action.payload }
      };
      setState(state);
      break;

    case 'BOX_BUFFER':
      state = {
        ...state,
        ...{ buffer: action.payload }
      };
      setState(state);
      break;

    case 'CURRENT_EQUIPMENT':
      state = {
        ...state,
        ...{ equipment: action.payload }
      };
      setState(state);
      break;

    case 'NETWORK_STATUS':
      state = {
        ...state,
        ...{ isOffline: action.payload }
      };
      setState(state);
      break;

    default:
      break;
  }

  return state;
};
