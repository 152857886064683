import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
// import Datetime from 'react-datetime';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import 'moment/locale/pt-br';
import { MainContext } from '../../contexts/mainContext';


import './style.css';

import { Modal, Content, Header, Footer, Body } from './styles';
import { FaRegTimesCircle } from 'react-icons/fa';

import { useSubscription, useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { UPDATE_BOX, SET_BOX_EDITION } from '../../graphql/mutations';

function BoxEditionModal({ box, onCloseModal, refreshBoxes }) {
  const [waitingUpdate, setWaitingUpdate] = useState(false);
  const [updateSucessfull, setUpdateSucessfull] = useState(null);
  const [editedBoxValues, setEditedBoxValues] = useState(null);

  const { state, dispatch } = useContext(MainContext);
  const { id_enterprise, equipment, id_site } = state;

  const closeWithDelay = async () => {
    if (updateSucessfull) {
      await new Promise((res) => setTimeout(res, 1000));
      onCloseModal();
      refreshBoxes();
    }
  };

  useEffect(() => {
    closeWithDelay();
  }, [updateSucessfull]);

  const [
    updateBox,
    {
      data: boxUpdateData
    }] = useMutation(UPDATE_BOX);

  useEffect(() => {
    const closeOnEscapeKey = (e) => {
      if ((e.charCode || e.keyCode) === 27) {
        onCloseModal();
      }
    };

    document.body.addEventListener('keydown', closeOnEscapeKey);
    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKey);
    };
  }, [onCloseModal]);

  useEffect(() => {
    if (box) {
      setEditedBoxValues(box);
    }
  }, [box]);

  useEffect(() => {
    if (waitingUpdate && boxUpdateData) {
      setWaitingUpdate(false);
      setUpdateSucessfull(true);
    }
  }, [boxUpdateData]);

  const LoadingIndicator = (props) => (
    waitingUpdate &&
    <div className="spinner-grow text-light" role="status"><span className="visually-hidden">Loading...</span></div>
  );

  const handleChangeSubmit = async (data) => {
    setWaitingUpdate(true);
    setUpdateSucessfull(null);
    try {
      updateBox({
        variables:
        {
          box_order_number: box.box_order_number,
          increment: box.increment,
          id_enterprise,
          id_equipment: parseInt(equipment?.id),
          id_order: box.id_order,
          id_production_order: box.id_production_order,
          id_site: id_site,
          ts_value: moment(box.ts_value).format(),
          id_box: box.id_box,
          deleted: false,
          new_ts_value: moment(editedBoxValues.ts_value).format(),
          new_increment: editedBoxValues.increment
        }
      });
    } catch (err) {
      console.log(err);
      alert(err);
      setUpdateSucessfull(false);
    }
  };

  if (!box) {
    return null;
  }

  return (
    <Modal className="modal hide" onClick={onCloseModal} id="UploadDowntimeModal" tabIndex="-1">
      <div className="modal-dialog hide">
        <Content onClick={(e) => {
          e.stopPropagation();
        }}>
          <Header>
            <h5 className="modal-title">Edição de caixa:</h5>
            <FaRegTimesCircle onClick={onCloseModal} />
          </Header>
          <Body>
            <form id="UploadCSVForm" >

              <div>

                <label htmlFor="incrment_input" >Quantidade: </label>
                <input
                  id="incrment_input"
                  value={editedBoxValues?.increment}
                  onChange={(e) => {
                    let temp = { ...editedBoxValues };
                    temp.increment = e.target.value;
                    setEditedBoxValues(temp);
                  }}
                  style={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}
                />
              </div>
              <div>

                <label htmlFor="datetime_input">Horário: </label>
                <Datetime
                  value={moment(editedBoxValues?.ts_value).toDate()}
                  // value=
                  timeFormat="HH:mm:ss"
                  dayFormat="DD/MM/YYYY"
                  id="datetime_input"
                  onChange={(e) => {
                    let temp = { ...editedBoxValues };
                    temp.ts_value = e;
                    setEditedBoxValues(temp);
                  }}
                  locale="pt-br"
                  className="date_picker_modal"
                />


              </div>
            </form>
          </Body>

          <Footer>
            {updateSucessfull != null ?
              (
                updateSucessfull ? <p className=".text-success" >Alteração salva com sucesso!</p> : <p className=".text-danger">Erro ao salvar alteração!</p>
              ) :
              <></>
            }
            <button type="button" className={`btn btn-secondary ${waitingUpdate ? 'disabled' : ''}`} onClick={onCloseModal} >Close</button>
            <button type="button" onClick={() => handleChangeSubmit()} >{waitingUpdate ? <LoadingIndicator /> : <></>}{waitingUpdate ? '' : 'Send'}</button>
          </Footer>
        </Content>
      </div>
    </Modal>
  );
}

export default BoxEditionModal;