import React from 'react';
import moment from 'moment';
import { FaPen, FaPencilAlt, FaTrashAlt, FaListAlt } from 'react-icons/fa';
import { Container, AddBoxButton } from './styles';
import BoxEditionModal from '../../components/BoxEditionModal';
import BoxDeleteModal from '../../components/BoxDeleteModal';
import BoxAddModal from '../../components/BoxAddModal';
import BoxDetailsModal from '../../components/BoxDetailsModal';


function BoxEditionTable({ boxes_edition, refreshBoxes }) {
  const [editionBox, setEditionBox] = React.useState(null);
  const [showAddModal, setShowAddModal] = React.useState(false);
  const [deleteBox, setDeleteBox] = React.useState(null);
  const [detailsBox, setDetailsBox] = React.useState(null);

  // Controle do modal de adicionar caixas
  const closeAddModal = () => {
    setShowAddModal(false);
  };
  const openAddModal = () => {
    setShowAddModal(true);
  };


  const closeModal = () => {
    setEditionBox(null);
  };
  const openModal = () => {
    setShowModal(true);
  };
  const handleEditButton = (e) => {
    setEditionBox(e);
  };


  const handleDetails = (e) => {
    setDetailsBox(e);
  };
  const closeDetailsModal = (e) => {
    setDetailsBox(null);
  };

  const closeDeleteModal = () => {
    // setShowModal(false);
    setDeleteBox(null);
  };
  const handleDeleteButton = (e) => {
    setDeleteBox(e);
  };

  return (
    <Container>
      {showAddModal ? <BoxAddModal onCloseModal={closeAddModal}  refreshBoxes={refreshBoxes}/> : null}
      <BoxEditionModal onCloseModal={closeModal} box={editionBox} refreshBoxes={refreshBoxes}/>
      <BoxDeleteModal onCloseModal={closeDeleteModal} box={deleteBox} refreshBoxes={refreshBoxes}/>
      <BoxDetailsModal onCloseModal={closeDetailsModal} box={detailsBox} refreshBoxes={refreshBoxes}/>

      <AddBoxButton onClick={openAddModal}>
        Adicionar Caixa
      </AddBoxButton>
      <table style={{ width: '90%' }}>
        <tbody>
          {boxes_edition?.map((item, index) =>
          (
            <>
              <tr key={index}>
                <td style={{ verticalAlign: 'middle' }}>Número: <strong>{item.box_order_number}</strong></td>
                <td style={{ verticalAlign: 'middle' }}>Op: <strong>{item.id_order}</strong></td>
                <td style={{ verticalAlign: 'middle' }}>Turno: <strong>{item.cd_shift}</strong></td>
                <td style={{ verticalAlign: 'middle' }}>Horário: <strong>{moment(item.ts_value).format('HH:mm:ss DD/MM/YYYY')}</strong></td>
                <td style={{ verticalAlign: 'middle' }}>Quantidade: <strong>{item.increment}</strong></td>
                <td style={{ verticalAlign: 'middle' }}>
                  <FaTrashAlt style={{ margin: '5px' }} onClick={() => handleDeleteButton(item)} />
                  <FaPencilAlt style={{ margin: '5px' }} onClick={() => handleEditButton(item)} />
                  {item?.scanned_boxes_editions?.length > 0 ?
                    <FaListAlt style={{ margin: '5px' }} onClick={() => handleDetails(item)} />
                    : null}
                </td>
              </tr>


              {/* {boxes_edition?.map((item, index) =>
                      (
                        <tr key={index}>
                          <td></td>
                          <td>Núaaaamero: <strong>{item.box_order_number}</strong></td>
                          <td>Op: <strong>{item.id_order}</strong></td>
                          <td>Turno: <strong>{item.cd_shift}</strong></td>
                          <td>Horário: <strong>{moment(item.ts_value).format('HH:mm:ss DD/MM/YYYY')}</strong></td>
                        </tr>
                      )
                    )
                    } */}
            </>
          )
          )
          }

        </tbody>

      </table>
    </Container >
  );
}

export default BoxEditionTable;