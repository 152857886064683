import _invertColor from 'invert-color';

export default {

  blue: '#2F80ED',
  blueDark: '#346EBC',
  blueLight: '#659DEC',
  blueLighter: '#D0DBE6',

  green: '#27AE60',
  greenLighter: '#E3F0E5',

  orange: '#F2994A',
  orangeLighter: '#E6D8D0',

  primary: '#2F80ED',
  secondary: '#346EBC',

  gray: '#828282',
  grayLight: '#BDBDBD',
  grayLighter: '#E2E2E7',

  dark: '#141922',
  light: '#EAEAED',

  success: '#27AE60',
  warning: '#ffc940',
  danger: '#a82a2a',

  disabled: '#a7b6c2',

  black: '#202b33',
  white: '#ffffff'

};

const invertColorConfig = {
  black: '#202b33',
  white: '#FFFFFF',
  threshold: .8
};

const RGBLetterToHEX = (rgb) => {
  let hex = Number(rgb).toString(16);
  if (hex.length < 2) hex = '0' + hex;
  return hex;
};

const fullRGBToHEX = (r, g, b) => {
  const red = RGBLetterToHEX(r);
  const green = RGBLetterToHEX(g);
  const blue = RGBLetterToHEX(b);
  return `#${red + green + blue}`;
};

const getRGB = (string) => {
  if (!string) return '';
  const match = string.match(
    /rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d?))\))?/
  );
  return match ? [match[1], match[2], match[3]] : [];
};

export const invertColor = (color, config) => {
  if (!color) return '';
  const isHEX = /^#[0-9A-F]{6}$/i.test(color);
  if (!isHEX) color = fullRGBToHEX(...getRGB(color));
  return _invertColor(color, config || invertColorConfig);
};

export const convertHexToRGB = (hex, opacity, returnAsArray) => {
  if (!hex) return '';
  hex = hex.replace('#', '');
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);
  let result = `rgb(${r}, ${g}, ${b})`;
  if (typeof opacity === 'number') result = `rgba(${r}, ${g}, ${b}, ${opacity})`;
  if (returnAsArray) result = [r, g, b];
  return result;
};

export const convertRGBToHSL = (rgb) => {
  const r = rgb[0] / 255;
  const g = rgb[1] / 255;
  const b = rgb[2] / 255;
  const min = Math.min(r, g, b);
  const max = Math.max(r, g, b);
  const delta = max - min;
  let h;
  let s;
  if (max === min) {
    h = 0;
  } else if (r === max) {
    h = (g - b) / delta;
  } else if (g === max) {
    h = 2 + (b - r) / delta;
  } else if (b === max) {
    h = 4 + (r - g) / delta;
  }
  h = Math.min(h * 60, 360);
  if (h < 0) {
    h += 360;
  }
  const l = (min + max) / 2;
  if (max === min) {
    s = 0;
  } else if (l <= 0.5) {
    s = delta / (max + min);
  } else {
    s = delta / (2 - max - min);
  }
  return [h, s * 100, l * 100];
};

export const darken = (color, ratio) => {
  let hsl = convertRGBToHSL(convertHexToRGB(color, null, true));
  hsl[2] -= hsl[2] * ratio;
  return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;
};

export const lighten = (color, ratio) => {
  let hsl = convertRGBToHSL(convertHexToRGB(color, null, true));
  hsl[2] += hsl[2] * ratio;
  return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;
};
